<template>
  <!-- begin::Fares tabs for TransporterAdmin Role -->
  <div v-if="isAuthenticated">
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/tarifas-primary.svg"
      />
      <div>
        {{ $t("MENU.FARES") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <v-tabs v-model="ActiveTab" align-with-title class="mb-3 mx-0">
      <v-tabs-slider color="#61bb0c"></v-tabs-slider>

      <v-tab grow v-for="t in TabList" :key="t.Position" :href="'#' + t.Name">
        <span v-if="t.BadgeCount === 0">
          {{ t.Title }}
        </span>
        <v-badge v-else inline color="bg-danger text-white">
          <template v-slot:badge>
            {{ t.BadgeCount }}
          </template>
          <span class="mr-3">{{ t.Title }}</span>
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-tabs-items class="mx-0" :value="ActiveTab">
      <v-tab-item v-for="t in TabList" :key="t.Position" :value="t.Name">
        <div v-if="t.Name === 'standard'">
          <FaresStandardIndex />
        </div>

        <div v-if="t.Name === 'tender'">
          <FaresTenderIndex />
        </div>

        <div v-if="t.Name === 'p2p'">
          <FaresP2PIndex />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <!-- end::Fares tabs for TransporterAdmin -->
</template>

<script>
import { mapGetters } from "vuex";

import FareTenderRouteService from "@/core/services/api/v2/faretenderroute.service";

import FaresP2PIndex from "@/view/pages/manager/fares/p2p/Index.vue";
import FaresStandardIndex from "@/view/pages/manager/fares/standard/Index.vue";
import FaresTenderIndex from "@/view/pages/manager/fares/tender/Index.vue";

export default {
  name: "FaresTabs",
  components: {
    FaresP2PIndex,
    FaresStandardIndex,
    FaresTenderIndex
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ActiveTab: {
      set(ActiveTab) {
        this.$router.replace({ query: { ...this.$route.query, ActiveTab } });
      },
      get() {
        return this.$route.query.ActiveTab;
      }
    }
  },
  data() {
    return {
      TabList: [
        {
          Position: 0,
          Name: "standard",
          Title: this.$i18n.t("FARES.STANDARD.TITLE_INDEX"),
          BadgeCount: 0
        },
        {
          Position: 1,
          Name: "tender",
          Title: this.$i18n.t("FARES.TENDER.TITLE_INDEX"),
          BadgeCount: 0
        },
        {
          Position: 2,
          Name: "p2p",
          Title: this.$i18n.t("FARES.P2P.TITLE_INDEX"),
          BadgeCount: 0
        }
      ]
    };
  },

  mounted() {
    this.countFareTenderWaitingPrice();
  },

  methods: {
    countFareTenderWaitingPrice() {
      FareTenderRouteService.countToMyTransporterWaitingPrice().then(
        response => {
          this.TabList[1].BadgeCount = response;
        }
      );
    }
  }
};
</script>
