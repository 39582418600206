<template>
  <!--begin::FaresP2P index-->
  <div class="gray-box">
    <div class="search-info mb-6 d-flex align-items-top">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      <span>
        {{ $t("FARES.P2P.TITLE_INDEX_INFO_1") }}
        <br />
        {{ $t("FARES.P2P.TITLE_INDEX_INFO_2") }}
      </span>
    </div>

    <div
      class="white-box shadow-box p-12"
      title="Pendiente de definir"
      style="height: 50vh"
    >
      TBD...
    </div>
  </div>
  <!--end::FaresP2P index-->
</template>

<script>
export default {
  name: "FaresP2PIndex"
};
</script>
