<template>
  <!--begin::FaresTender index-->
  <div class="gray-box">
    <div class="search-info mb-6 d-flex align-items-top">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      <span>
        {{ $t("FARES.TENDER.TITLE_INDEX_INFO_1") }}
        <br />
        {{ $t("FARES.TENDER.TITLE_INDEX_INFO_2") }}
      </span>
    </div>

    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row pt-6 align-items-top">
        <div class="col-md-6 d-flex d-row align-items-top">
          <h5 class="mr-3 mt-3 font-weight-bolder" style="white-space: pre">
            {{ $t("FARES.COMMON.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::Table Results-->
    <v-data-table
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.SenderCompany.TradeName }}
          </td>
          <td>
            {{ item.SenderCompany.FiscalID }}
          </td>
          <td>
            <span
              class="white-box border-2 p-3 font-size-sm text-uppercase"
              :class="[item.CountWaitingPrice === 0 ? 'opacity-50' : '']"
              style="white-space: nowrap"
            >
              {{
                $tc("FARES.TENDER.FARES_WAITING_PRICE", item.CountWaitingPrice)
              }}
            </span>
          </td>
          <td>
            <span class="action-icon" @click="searchFromSender(item)">
              <inline-svg src="/media/icons/view-primary.svg" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchFares(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!--end::FaresTender index-->
</template>

<script>
import FareTenderRouteService from "@/core/services/api/v2/faretenderroute.service";

export default {
  name: "FaresTenderIndex",
  data() {
    return {
      TableHeaders: [
        {
          text: this.$t("FREQUENT_ADDRESSES.NICK"),
          value: "SenderCompany.TradeName"
        },
        {
          text: this.$t("COMPANIES.FISCAL_ID"),
          value: "SenderCompany.FiscalID"
        },
        {
          text: this.$t("FARES.TENDER.STATUS"),
          value: "CountWaitingPrice"
        },
        { text: this.$t("GENERIC.ACTIONS"), width: 60, sortable: false }
      ],
      options: {},
      SortBy: "",
      SortDesc: false,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ]
    };
  },

  mounted() {
    // Do first search
    this.searchFares(false);
  },

  methods: {
    searchFares(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      FareTenderRouteService.searchToMyTransporter(
        this.SearchText,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.FareTenderRoutes;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchFares(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchFares(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchFares(false);
    },

    changeItemsCurrentPage() {
      this.searchFares(true);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchFares(false);
      }, 600);
    },

    searchFromSender(item) {
      FareTenderRouteService.cleanCachedFilters();
      window.sessionStorage.setItem(
        "FareTenderRouteSenderName",
        item.SenderCompany.TradeName
      );

      this.$router.push(
        "/manager/fares/tender/fromsender/" + item.SenderCompany.CompanyID
      );
    }
  }
};
</script>
